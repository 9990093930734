import React from "react";
import "./LetUsTalk.css";

function LetUsTalk() {
  return (
    <div className="letustalk-section hidden">
      <p>
        If you want to consult one-on-one, you can call or WhatsApp. Our mentor
        will reach out to you as soon as possible.
      </p>
    </div>
  );
}

export default LetUsTalk;
