import React from "react";

import "./services.css";
import {ReactComponent as Icon} from "../../assets/images/icons/icon-design.svg";
import {ReactComponent as Icon2} from "../../assets/images/icons/icon-development.svg";
import {ReactComponent as Icon3} from "../../assets/images/icons/icon-helpdesk.svg";
import {ReactComponent as Icon4} from "../../assets/images/icons/icon-training.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenRuler
} from "@fortawesome/free-solid-svg-icons";




function Services() {
  // const mentorUrl = '/mentors';

  return (
    <>
     <div className="bg-container bg-cream"  id="services">
      <section className="relative isolate overflow-hidden bg-white">
           <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
            <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
            <div className="mx-auto w-10/12 lg:py-[25px] py-8">
              <div className="services-content" id="services">
                  <h2 className="mx-auto text-center text-brown  font-bold leading-7 font-bold tracking-tight uppercase text-2xl lg:text-3xl" >
                    Our Services
                    </h2>
                  <div className=" grid grid-col-1  grid-flow-row-dense gap-4 mt-4 md:grid-cols-2 xl:grid-cols-4 md:mt-10 ">
                    <div class="services-card p-8 active">
                        <div class="choose-us-img">
                        <Icon className='icon-blue'/>
                        </div>
                        <div class="choose-us-content">
                          <h3 class="title font-medium mt-6 mb-4 "><a href="#">Website/Application Design & Development</a></h3>
                          <p>We provide a platform to accelerate your business from establishment to online presence, enabling various business objectives such as brand promotion and e-commerce transactions.</p>
                          {/* <a href="#" class="inline-block mt-6 text-blue">
                            <button
                              class="flex items-center gap-2  text-base font-medium align-middle transition-all  select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none"
                              type="button">
                              Learn More
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
                              </svg>
                            </button>
                          </a> */}
                        </div>
                    </div>
                    <div class="services-card p-8 ">
                        <div class="choose-us-img">
                           <Icon2 className='icon-blue'/>
                        </div>
                        <div class="choose-us-content">
                          <h3 class="title font-medium mt-6 mb-4 "><a href="#">Online training on Dot Net, Java and UI UX </a></h3>
                          <p>Participants will gain hands-on experience and in-depth knowledge in the latest technologies and programming languages.</p>
                          {/* <a href="#" class="inline-block mt-6 text-blue">
                            <button
                              class="flex items-center gap-2   text-base font-medium align-middle transition-all  select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none "
                              type="button">
                              Learn More
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
                              </svg>
                            </button>
                          </a> */}
                        </div>
                    </div>
                    <div class="services-card p-8 ">
                        <div class="choose-us-img">
                           <Icon4 className='icon-blue'/>
                        </div>
                        <div class="choose-us-content">
                          <h3 class="title font-medium mt-6 mb-4 "><a href="#">Assignment Help</a></h3>
                          <p>We offer support and assistance to students in completing their assignments and project tasks. This service is designed to aid students who may face challenges with understanding course materials</p>
                          {/* <a href="#" class="inline-block mt-6 text-blue">
                            <button
                              class="flex items-center gap-2   text-base font-medium align-middle transition-all  select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none "
                              type="button">
                              Learn More
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
                              </svg>
                            </button>
                          </a> */}
                        </div>
                    </div>
                    <div class="services-card p-8 ">
                        <div class="choose-us-img">
                           <Icon3 className='icon-blue'/>
                        </div>
                        <div class="choose-us-content">
                          <h3 class="title font-medium mt-6 mb-4 "><a href="#">Digital Marketing and SEO</a></h3>
                          <p>Unlock the full potential of your online presence with our expert SEO and Digital Marketing services. Elevate your brand visibility, drive targeted traffic, and amplify your digital footprint. </p>
                          {/* <a href="#" class="inline-block mt-6 text-blue">
                            <button
                              class="flex items-center gap-2   text-base font-medium align-middle transition-all  select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none "
                              type="button">
                              Learn More
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                                stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"></path>
                              </svg>
                            </button> 
                          </a>*/}
                        </div>
                    </div>
                   
                    
                  </div>
              </div>
            </div>    
         </section>
      </div>  
    </>
  );
}

export default Services;
