import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import {
  faShareNodes,
  faUsersLine,
  faClock,
  faCirclePlay,
  faCircleCheck,
  faStar,
  faUser,
  faComment,
  faStarHalfStroke,
  faCheck
} from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";


import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${id === open ? "rotate-180" : ""} h-5 w-5 transition-transform`}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
    </svg>
  );
}


export default function JavaCourse() {

  const [open, setOpen] = React.useState(1);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  const [activeTab, setActiveTab] = useState('tab-1');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const location = useLocation();

  useEffect(() => {
    const elementId = location.pathname.substring(1); // Remove the leading '/' from the URL
    scrollToElement(elementId);
  }, [location]);

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  
  return (
    <>
    <div className="bg-container bg-light-2" id="courses/java">
      <div className=" content-container mx-auto max-w-7xl px-4 xl:px-0">
          <div className="courses-header-section">
              <div className="course-heading">
                <h1 className="font-bold leading-7 text-2xl lg:text-3xl tracking-tight ">
                Basic and Advance Java
                </h1>
              </div>
             <div className="courses-aside mt-6 flex text-sm"> 
                <div className="flex-1">
                    <div className="course-tag">
                      <span>Categories: </span>
                        <a href="" className="tracking-tight text-gray-950">Development</a>, 
                        <a href="" className="tracking-tight text-gray-950 ml-1">Java</a>,
                        <a href="" className="tracking-tight text-gray-950 ml-1">MySql</a>,
                        <a href="" className="tracking-tight text-gray-950 ml-1">Mongo</a>,
                    </div>
                </div>
                
                <div class="flex-1 justify-items-end">
                  <div class="flex justify-end">
                      <a  href="#" class="text-slate-600 hover:text-blue-800"> 
                      <FontAwesomeIcon icon={faShareNodes} /> Share</a>
                  </div>
                </div>
              </div>
          </div>
      </div>
      </div>

      <div className="bg-container">
        <div className=" mx-auto max-w-7xl pt-5 pb-10 px-4 xl:px-0">
       
          <section class="layout-pt-md layout-pb-md">
              <div class="page-nav-menu -line">
                <div class="flex gap-6">
                <div>
                  <a href="#overview"   className={`pb-4 page-nav-menu__link ${activeTab === 'tab-1' ? 'is-active' : ''}`}
                  onClick={() => handleTabClick('tab-1')}>Who should join this course?</a></div>
                    
                  <div>
                    <a href="#course-content"   className={`pb-4 page-nav-menu__link ${activeTab === 'about' ? 'is-active' : ''}`}
                    onClick={() => handleTabClick('about')}>Course Content</a></div>
                </div>
              </div>
              {/* Overview section */}
              <div id="overview" class="pt-6 lg:pt-10 to-over">
                <h2 class=" leading-7 text-lg lg:text-xl tracking-tight mb-6 font-medium">Who should join this course?</h2>
                <div class="show-more  mt-30 js-show-more is-active ">
                    <div class="show">
                    <ul className="ul-list y-gap-15 pt-30 list-disc">
                      <li>Even if you don't have programing backgroud</li>
                      <li>If you want to learn Java from Basic to Advance.</li>
                      <li>If you want to become a Java developer</li>
                    </ul>
                    </div>
                </div>
              </div>
              {/* Course section */}
              <div id="course-content" class="pt-10 lg:pt-20">
                <h2 class="leading-7 text-lg lg:text-2xl tracking-tight mb-6 font-medium">Course Content</h2>
                {/* <div class="flex justify-between items-center mt-30">
                    <div class="">27 sections • 95 lectures</div>
                    <a href="#" class="underline text-blue">Expand All Sections</a>
                </div> */}
                <div class="mt-6">
                <Accordion className="accordion accordion-container" open={open === 1} icon={<Icon id={1} open={open} />}>
                  <AccordionHeader className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']" onClick={() => handleOpen(1)}>
                    <div className="accordion-header-heading flex-none text-base ">
                    Java Programming Fundamentals
                    </div>
                     {/* <div className="course-duration flex-1 text-right text-base ">6 lectures</div> */}
                     </AccordionHeader>
                     <AccordionBody className="accordion-content text-base font-normal text-gray">
                     <ul className="ul-list y-gap-15 pt-30 list-disc">
                        <li className="ul-list y-gap-15 pt-30 list-disc">Introduction to Java
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>Overview of Java </li>
                              <li>Setting up Java Development Environment </li>
                            </ul>
                        </li>
                        <li className="ul-list y-gap-15 pt-30 list-disc">Basic Syntax and Data Types
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>Variables and Data Types </li>
                              <li>Operators and Expressions </li>
                              <li>Control Flow Statements (if, switch, loops) </li>
                            </ul>
                        </li>
                        <li className="ul-list y-gap-15 pt-30 list-disc">Object-Oriented Programming (OOP) in Java
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>Classes and Objects </li>
                              <li>Inheritance and Polymorphism </li>
                              <li>Encapsulation and Abstraction </li>
                            </ul>
                        </li>
                        <li className="ul-list y-gap-15 pt-30 list-disc">Exception Handling
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>Understanding Exceptions </li>
                              <li>Try-Catch Blocks </li>
                              <li>Custom Exception Classes</li>
                            </ul>
                        </li>
                      </ul>
                     </AccordionBody>
                </Accordion>
                <Accordion className="accordion accordion-container" open={open === 2} icon={<Icon id={2} open={open} />}>
                  <AccordionHeader className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']" onClick={() => handleOpen(2)}>
                  <div className="accordion-header-heading flex-none text-base">
                  Advanced Java Concepts
                    </div>
                     {/* <div className="course-duration flex-1 text-right text-base">6 lectures</div> */}
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <div class="flex justify-between mb-4">
                        
                      <ul className="ul-list y-gap-15 pt-30 list-disc">
                        <li className="ul-list y-gap-15 pt-30 list-disc">Collections Framework
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>Arrays, Lists, Sets, Maps </li>
                              <li>Iterators and Comparators </li>
                            </ul>
                        </li>
                        <li className="ul-list y-gap-15 pt-30 list-disc">File Handling in Java
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>Reading and Writing Files </li>
                              <li>File I/O Operations </li>
                            </ul>
                        </li>
                        <li className="ul-list y-gap-15 pt-30 list-disc">Multithreading in Java
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>Understanding Threads </li>
                              <li>Synchronization and Thread Safety </li>
                            </ul>
                        </li>
                        </ul>
                    </div>
                  </AccordionBody>
                </Accordion>
                <Accordion className="accordion accordion-container" open={open === 3} icon={<Icon id={3} open={open} />}>
                  <AccordionHeader className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']" onClick={() => handleOpen(3)}>
                  <div className="accordion-header-heading flex-none text-base">
                  Java Application Development
                    </div>
                     {/* <div className="course-duration flex-1 text-right text-base">6 lectures</div> */}
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <div class="flex justify-between mb-4">
                        
                      <ul className="ul-list y-gap-15 pt-30 list-disc">
                        <li className="ul-list y-gap-15 pt-30 list-disc">Graphical User Interface (GUI) Programming with Swing
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>Components and Containers </li>
                              <li>Event Handling </li>
                            </ul>
                        </li>
                        <li className="ul-list y-gap-15 pt-30 list-disc">Introduction to JavaFX
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>Building User Interfaces with JavaFX </li>
                              <li>Scene Builder </li>
                            </ul>
                        </li>
                        <li className="ul-list y-gap-15 pt-30 list-disc">Database Connectivity with JDBC
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>Connecting to Databases </li>
                              <li>Executing MySql Queries </li>
                            </ul>
                        </li>
                        </ul>
                    </div>
                  </AccordionBody>
                </Accordion>
                <Accordion className="accordion accordion-container" open={open === 4} icon={<Icon id={4} open={open} />}>
                  <AccordionHeader className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']" onClick={() => handleOpen(4)}>
                  <div className="accordion-header-heading flex-none text-base">
                  Web Development with Java
                    </div>
                     {/* <div className="course-duration flex-1 text-right text-base">6 lectures</div> */}
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <div class="flex justify-between mb-4">
                        
                      <ul className="ul-list y-gap-15 pt-30 list-disc">
                        <li className="ul-list y-gap-15 pt-30 list-disc">Servlets
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>Introduction to Servlets </li>
                              <li>Handling HTTP Requests and Responses </li>
                            </ul>
                        </li>
                        <li className="ul-list y-gap-15 pt-30 list-disc">JavaServer Pages (JSP)
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>JSP Syntax and Elements </li>
                              <li>Integrating Java with HTML </li>
                            </ul>
                        </li>
                        <li className="ul-list y-gap-15 pt-30 list-disc">Introduction to Spring Framework
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>Dependency Injection </li>
                              <li>Spring MVC Architecture </li>
                            </ul>
                        </li>
                        </ul>
                    </div>
                  </AccordionBody>
                </Accordion>
                <Accordion className="accordion accordion-container" open={open === 5} icon={<Icon id={5} open={open} />}>
                  <AccordionHeader className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']" onClick={() => handleOpen(5)}>
                  <div className="accordion-header-heading flex-none text-base">
                  Database Management with MySQL
                    </div>
                     {/* <div className="course-duration flex-1 text-right text-base">6 lectures</div> */}
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <div class="flex justify-between mb-4">
                        
                      <ul className="ul-list y-gap-15 pt-30 list-disc">
                        <li className="ul-list y-gap-15 pt-30 list-disc">Introduction to MySQL
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>Database Basics </li>
                              <li>SQL Queries and Commands</li>
                            </ul>
                        </li>
                        <li className="ul-list y-gap-15 pt-30 list-disc">Integration of Java with MySQL
                            <ul className="ul-list y-gap-15 pt-30 list-disc"> 
                              <li>JDBC Connectivity </li>
                              <li>Data Manipulation and Transactions </li>
                            </ul>
                        </li>
                        
                        </ul>
                    </div>
                  </AccordionBody>
                </Accordion>
                </div>
              </div>
          </section>
            
        </div>
      </div>
    </>
  );
}
