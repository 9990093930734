import React from "react";

import LetUsTalk from "../../components/common/LetUsTalk";
import CsharpCourseComponent from "../../components/courses/CsharpCourse";
import FullHeader from "../../utility/FullHeader";
import FullFooter from "../../utility/FullFooter";

export default function CsharpCourse() {
  return (
    <>
      <header>
        <FullHeader></FullHeader>
      </header>
      <main>
        <CsharpCourseComponent></CsharpCourseComponent>
        <LetUsTalk></LetUsTalk>
      </main>
      <footer>
        <FullFooter></FullFooter>
      </footer>
    </>
  );
}
