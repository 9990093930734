import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function PrivacyPolicy() {
  const location = useLocation();

  useEffect(() => {
    const elementId = location.pathname.substring(1); // Remove the leading '/' from the URL
    scrollToElement(elementId);
  }, [location]);

  const scrollToElement = (elementId) => {
    const element =
      elementId == ""
        ? document.getElementById("herosection")
        : document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div className="content-container mx-auto max-w-7xl " id="privacy-policy">
      
        <h1 className="text-2xl font-semibold mb-4">Privacy Policy</h1>
        <p className="mb-4">Effective date: 25th Dec 2023</p>
        <p className="mb-4">
          This Privacy Policy explains how Learn At House ("we," "our," or "us")
          collects, uses, and shares your personal information when you use our
          online educational services.
        </p>
        <h2 className="text-xl font-semibold mb-2">
          1. Information We Collect
        </h2>
        <p className="mb-4">
          We may collect personal information you provide when registering,
          using our services, or communicating with us.
        </p>
        {/* Repeat the above structure for each section of your Privacy Policy */}
        <h2 className="text-xl font-semibold mb-2">
          2. How We Use Your Information
        </h2>
        <p className="mb-4">
          We may use your personal information to provide, maintain, and improve
          our services, as well as to respond to your requests and send you
          educational content.
        </p>
        {/* Add more sections as needed */}
        <h2 className="text-xl font-semibold mb-2">
          3. Disclosure of Your Information
        </h2>
        <p className="mb-4">
          We do not share your personal information with third parties unless
          required by law or with your consent.
        </p>
        <p className="mb-4">
          Thank you for trusting Learn At House with your education!
        </p>
        <p className="text-sm">Last updated: 25th Dec 2023</p>
      </div>
    
  );
}

export default PrivacyPolicy;
