import React, { useState } from "react";
import "./SubscribeToNewsletter.css";
import db from "../../utility/FireBase";
import {Button} from "@material-tailwind/react";

const subscribesCollection = db.collection('subscribers');

const SubscribeToNewsletter = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    timestamp: '',
  });

  const [isSubscribing, setIsSubscribing] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
      // For other fields, update the value as usual
      setFormData({
        ...formData,
        [name]: value,
        timestamp: new Date().toISOString(),
      })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission logic here, e.g., send data to a server or perform validation
    setIsSubscribing(true);
    const docRef = await subscribesCollection.add(formData);
    console.log('Subscriber ID: ', docRef.id);
    // Reset the form after submission
    setFormData({
      fullName: "",
      email: "",
      timestamp: '',
    });
    setIsSubscribing(false);
  };

  return (
      <div className="bg-container bg-cream">
        <section className="relative isolate overflow-hidden bg-white ">
        <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
        <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
          <div className="mx-auto w-10/12 lg:py-[60px] py-8" >
            <div className="subscribeToNewsletter-section ">
              <h2 className="mx-auto text-center text-brown  font-bold leading-7 font-bold tracking-tight uppercase text-2xl lg:text-3xl">Subscribe to Our Newsletter</h2>
              <p className="mt-6 text-lg leading-8 mx-auto max-w-2xl lg:max-w-4xl text-center">Stay updated with the latest news and promotions!</p>
              <form onSubmit={handleSubmit}>
                <div className="subscribe-form mt-10 flex flex-wrap">
                  {/* <label>Name:</label> */}
                  <input
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    required
                    autocomplete="" 
                    placeholder="Enter Your Name"
                    class="block flex-auto md:flex-1  w-screen rounded-md border bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                  {/* <label>Email:</label> */}
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    placeholder="Enter Your Email"
                    class="block flex-auto md:flex-1  w-screen rounded-md border bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  />
                  <Button className="h-3r bg-material-primary" ripple={true} type="submit" disabled={isSubscribing}>
                    {isSubscribing ? 'Submitting...' : 'Submit'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
  );
};

export default SubscribeToNewsletter;
