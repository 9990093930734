import React from "react";

import "./AboutUs.css";
import FullHeader from "../../utility/FullHeader";
import AllSkillsImage from "../../assets/images/about-us.jpg";
import MoreInfoButton from "../../utility/MoreInfoButton";

function AboutUs() {
  const mentorUrl = "/mentors";

  return (
    <>
      <header className="hidden">
        <FullHeader></FullHeader>
      </header>
      <div className="bg-container bg-cream">
        <div className="mx-auto w-10/12 lg:py-[60px] py-8 grid grid-cols-1 grid-flow-row-dense gap-4 sm:grid-cols-3" id="aboutus">
          <div className="aboutus-image">
            <img src={AllSkillsImage} alt="" />
          </div>
          <div className="aboutus-info col-span-2">
            <h2 className="aboutus-heading  font-bold leading-7 text-2xl lg:text-3xl font-bold tracking-tight uppercase ">
              About Us
            </h2>
            <p className="aboutus-description py-3">
              Welcome to Learn at House, your go-to online platform for
              comprehensive technical training. Our Frontend courses cover HTML,
              CSS, Bootstrap, Tailwind, JavaScript, jQuery, Angular, React,
              Blazor, and more, ensuring you master the skills needed for modern
              web development.
            </p>
            <p className="aboutus-description py-3">
              Explore the intricacies of Backend development with courses in C#,
              .NET MVC, Web API Core, LINQ, and Entity Framework Core. Delve
              into essential concepts like Design Patterns and SOLID Principles,
              while expanding your proficiency in languages such as Java, Node,
              Python, and others.
            </p>
            <p className="aboutus-description py-3">
              Dive into the realm of databases with our courses covering SQL
              Server, MySQL, Oracle, and MongoDB. From database design to
              optimization, our curriculum equips you with the knowledge needed
              to handle diverse data challenges in real-world scenarios.
            </p>
            <p className="aboutus-description py-3">
              Take your skills to the Cloud with courses on Azure, AWS, GCP, and
              more. Learn to deploy and manage applications in the cloud
              environment, gaining expertise in the technologies that power
              today's digital landscape. Join Learn at House and embark on a
              transformative learning journey from the comfort of your home.
              <MoreInfoButton redirectUrl={mentorUrl}></MoreInfoButton>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
