import FirebaseApp  from "firebase/compat/app";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAEEHJ1aSIcdHmcAj7h2h6MzQNP2rAnCr4",
  authDomain: "learnathouse-240b3.firebaseapp.com",
  projectId: "learnathouse-240b3",
  storageBucket: "learnathouse-240b3.appspot.com",
  messagingSenderId: "105972420693",
  appId: "1:105972420693:web:0d0c828120b94ad932c4dc",
  measurementId: "G-36ED0NZ1C9"
};

  const firebaseapp = FirebaseApp.initializeApp(firebaseConfig);
  const db = FirebaseApp.firestore();

export default db;
  