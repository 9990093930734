import React from "react";
import "./Testimonial.css";
import AsmaRazaq from "../../assets/images/testimonials/Asma.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

export default function Testimonial() {
  return (
    <div className="bg-container bg-white">
      <div className=" mx-auto w-10/12 ">
        <section className="relative isolate overflow-hidden bg-white ">
          <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
          <div className="absolute inset-y-0 right-1/2 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
          <div className="mx-auto max-w-2xl lg:max-w-4xl lg:py-[60px] py-8">
            <h2 className="mx-auto text-center text-brown font-bold leading-7  font-bold tracking-tight uppercase text-2xl lg:text-3xl">
              Testimonials
            </h2>
            <figure className="mt-10">
              <blockquote className="text-center text-xl font-semibold leading-8 text-gray-900 sm:text-2xl sm:leading-9">
                <p>
                  "Sudheshwer is a subject expert and has full command of .NET.
                  He clarified many .NET concepts for me. I definitely recommend
                  those who want to learn and code in C# and .NET to work with
                  Sudheshwer."
                </p>
              </blockquote>
              <figcaption className="mt-10">
                <img
                  className="mx-auto h-20 w-20 rounded-full"
                  src={AsmaRazaq}
                  alt="Asma Razaq"
                />
                <div className="mt-4 flex items-center justify-center space-x-3 text-base">
                  <div className="font-semibold text-gray-900">Asma Razaq</div>
                  <svg
                    viewBox="0 0 2 2"
                    width={3}
                    height={3}
                    aria-hidden="true"
                    className="fill-gray-900"
                  >
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                  <div className="text-gray-600">Full Stack .NET Developer</div>
                  <div>
                    <a
                      className="testimonial-linkedin"
                      href="https://www.linkedin.com/in/asma-razaq-a683311a5/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </section>
      </div>
    </div>
  );
}
