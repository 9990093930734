// FAQ.js

import React, { useState } from "react";
import "./FAQ.css";

const FAQ = () => {
  const [faqs, setFaqs] = useState([
    {
      question: "What courses do you offer?",
      answer:
        "We offer a comprehensive range of courses covering .NET And Java technologies. Our courses include beginner to advanced levels, covering topics such as C#, ASP.NET, Java programming, Spring framework, and more.",
    },
    {
      question: "Are your courses suitable for beginners?",
      answer:
        "Yes, we offer courses suitable for beginners, as well as intermediate and advanced learners. Our courses are designed to cater to a diverse audience, from those new to programming to experienced developers looking to enhance their skills.",
    },
    {
      question: "What are the prerequisites for the courses?",
      answer:
        "Prerequisites vary depending on the course. Basic knowledge of programming concepts is recommended for most courses. Check the individual course descriptions for specific prerequisites.",
    },
    ,
    {
      question: "Can I access course materials after completion?",
      answer:
        "Yes, once you've enrolled in a course, you'll have access to the course materials, including videos, slides, and code samples. You can review the content at your own pace even after completing the course.",
    },
    ,
    {
      question: "Are there any live instructor-led sessions?",
      answer:
        "Yes, we offer live instructor-led sessions for certain courses. Check the course details for information on live sessions and schedules.",
    },
    ,
    {
      question: "How can I contact support?",
      answer:
        "For any inquiries or assistance, you can reach our support team through the Contact Us page. We aim to respond to all queries promptly.",
    },
  ]);

  const toggleFaq = (index) => {
    setFaqs((prevFaqs) =>
      prevFaqs.map((faq, i) =>
        i === index ? { ...faq, isOpen: !faq.isOpen } : faq
      )
    );
  };

  return (
    <div className="bg-container bg-cream">
      <div className="mx-auto w-10/12 lg:py-[60px] py-8">
        <div className="faq-container ">
        <h2 className="mentor-heading mx-auto text-center  text-2xl lg:text-3xl font-bold leading-7  font-bold tracking-tight uppercase">Frequently Asked Questions</h2>
          {/* <p className="mt-6 text-lg leading-8 mx-auto max-w-2xl lg:max-w-4xl text-center">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet, quas exercitationem vel cumque unde sapiente placeat accusantium!
          </p> */}

            <div className="faq-listing mt-10">
                {faqs.map((faq, index) => (
                <div className="faq-item" key={index}>
                  <div
                    className={`faq-question ${faq.isOpen ? "open" : ""}`}
                    onClick={() => toggleFaq(index)}
                  >
                    <h3>{faq.question}</h3>
                    <span className="toggle-icon">{faq.isOpen ? "-" : "+"}</span>
                  </div>
                  {faq.isOpen && <p>{faq.answer}</p>}
                </div>
              ))}
            </div>

        </div>
      </div>
    </div>
  );
};

export default FAQ;
