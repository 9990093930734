import React from "react";
import "../../components/common/TopFooter.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSquareFacebook,
  faSquareTwitter,
  faSquareInstagram,
  faSquareYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

function TopFooter() {
  const facebookPageLink = "https://www.facebook.com/Learnathousetraining";
  const instagramPageLink = "https://www.instagram.com/learnathouse";
  const twitterPageLink = "#";
  const linkedInPageLink = "https://www.linkedin.com/company/learnathouse";
  const youtubeChannelLinkEnglish =
    "https://www.youtube.com/@lahinenglish?sub_confirmation=1";
  const youtubeChannelLinkHindi =
    "https://www.youtube.com/@lahinhindi?sub_confirmation=1";
  return (
    <div className="bg-container bg-blue">
      <div className="mx-auto w-10/12 lg:py-[60px] py-8">
        <div className="top-footer  grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
          <div className="useful-link">
            <h4 class="text-2xl lg:text-lg">Useful Links</h4>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/aboutus"> About Us </Link>
              </li>
              <li>
                <Link to="/term-of-service">Term of service</Link>
              </li>
              <li></li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>
          <div className="tutorials">
            <h4 class="text-2xl lg:text-lg">Courses</h4>
            <ul>
              <li>
                <Link to="/courses/csharp">CSharp</Link>
              </li>
              <li>
                <Link to="/courses/java">Java</Link>
              </li>
              {/* <li>
                <a href="#">Angular</a>
              </li> */}
            </ul>
          </div>
          <div className="contact-info">
            <h4 class="text-2xl lg:text-lg">Contact Info</h4>
            <ul>
              <li>
                <strong>Phone : +91 9873476934</strong>
              </li>
              <li>
                <strong>Phone : +91 8860858918</strong>
              </li>
              <li>
                <strong>Email : info@leanathouse.com</strong>
              </li>
            </ul>
          </div>
          <div className="social-connect">
            <h4 class="text-2xl lg:text-lg">Social Network</h4>
            <ul className="social-network">
              <li>
                <a
                  className="custom-icon-social-bottom"
                  href={facebookPageLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faSquareFacebook} />
                </a>
              </li>
              <li>
                <a
                  className="custom-icon-social-bottom"
                  href={instagramPageLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faSquareInstagram} />
                </a>
              </li>
              <li>
                {" "}
                <a
                  className="custom-icon-social-bottom"
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faSquareTwitter} />
                </a>
              </li>
              <li>
                {" "}
                <a
                  className="custom-icon-social-bottom"
                  href={linkedInPageLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faLinkedin} />
                </a>
              </li>
              <li>
                <a
                  className="custom-icon-social-bottom"
                  href={youtubeChannelLinkEnglish}
                  title="LearnAtHouse In English"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faSquareYoutube} />
                </a>
              </li>
              <li>
                <a
                  className="custom-icon-social-bottom"
                  href={youtubeChannelLinkHindi}
                  title="LearnAtHouse In Hindi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faSquareYoutube} />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopFooter;
