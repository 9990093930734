import React from "react";
import "./TopHeader.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faSquareFacebook,
  faSquareTwitter,
  faSquareInstagram,
  faSquareYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

function TopHeader() {
  const facebookPageLink = "https://www.facebook.com/Learnathousetraining";
  const instagramPageLink = "https://www.instagram.com/learnathouse";
  const twitterPageLink = "#";
  const linkedInPageLink = "https://www.linkedin.com/company/learnathouse";
  const youtubeChannelLinkEnglish =
    "https://www.youtube.com/@lahinenglish?sub_confirmation=1";
  const youtubeChannelLinkHindi =
    "https://www.youtube.com/@lahinhindi?sub_confirmation=1";
  const phoneNo1 = "+919873476934";
  const phoneNo2 = "+918860858918";
  const email = "info@learnathouse.com";
  return (
    <div className="bg-container bg-blue hidden sm:block px-4 xl:px-0 fixed  w-screen z-10">
      <div className=" mx-auto max-w-full	px-6 text-white">
        <div className="top-header flex flex-wrap">
          <div className="contact-info flex flex-wrap content-center">
            <a
              className="custom-icon-contact-top"
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faEnvelope} />
              {email}
            </a>
            <a
              className="custom-icon-contact-top"
              href={`tel:${phoneNo1}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faPhone} />
              {phoneNo1}
            </a>
            <a
              className="custom-icon-contact-top"
              href={`tel:${phoneNo2}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faPhone} />
              {phoneNo2}
            </a>
          </div>
          <div className="flex-wrap flex justify-start flex-1 sm:justify-end  lg:flex lg:flex-1 lg:justify-end ">
            <a
              className="text-white p-2 text-2xl  sm:block"
              href={facebookPageLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faSquareFacebook} />
            </a>
            <a
              className="text-white p-2 text-2xl  sm:block"
              href={instagramPageLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faSquareInstagram} />
            </a>
            <a
              className="text-white p-2 text-2xl  sm:block"
              href={twitterPageLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faSquareTwitter} />
            </a>
            <a
              className="text-white p-2 text-2xl  sm:block"
              href={linkedInPageLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a
              className="text-white p-2 text-2xl  sm:block"
              href={youtubeChannelLinkEnglish}
              target="_blank"
              title="LearnAtHouse In English"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faSquareYoutube} />
            </a>
            <a
              className="text-white p-2 text-2xl  sm:block"
              href={youtubeChannelLinkHindi}
              target="_blank"
              title="LearnAtHouse In Hindi"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faSquareYoutube} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopHeader;
