import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function TermOfService() {
  const location = useLocation();

  useEffect(() => {
    const elementId = location.pathname.substring(1); // Remove the leading '/' from the URL
    scrollToElement(elementId);
  }, [location]);

  const scrollToElement = (elementId) => {
    const element =
      elementId == ""
        ? document.getElementById("herosection")
        : document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  
  return (
    <div className="content-container mx-auto max-w-7xl " id="term-of-service">
        <h1 className="text-2xl font-semibold mb-4">Terms of Service</h1>
        <p className="mb-4">Welcome to Learn At House!</p>
        <p className="mb-4">
          By accessing and using our online training services, you agree to
          comply with and be bound by the following terms and conditions:
        </p>
        <h2 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
        <p className="mb-4">
          - You must read, understand, and accept these terms and conditions
          before using our services.
        </p>
        {/* Repeat the above structure for each section of your terms */}
        <h2 className="text-xl font-semibold mb-2">2. User Eligibility</h2>
        <p className="mb-4">
          - Users must be of legal age or have parental/guardian consent to use
          our platform. - You are responsible for maintaining the
          confidentiality of your account information.
        </p>
        {/* Add more sections as needed */}
        <p className="mb-4">
          Thank you for choosing Learn At House for your educational needs!
        </p>
        <p className="text-sm">Last updated: 25th Dec 2023</p>
      </div>
  );
}

export default TermOfService;
