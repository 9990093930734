import React from "react";
import TopHeader from "../components/common/TopHeader";
import NavBar from "../components/common/NavBar";

export default function FullHeader() {
  return (
    <>
      <TopHeader></TopHeader>
      <NavBar></NavBar>
    </>
  );
}
