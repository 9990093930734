import axios from 'axios';
import db from "../utility/FireBase";

const API_URL = 'https://api.learnathouse.com';
const contactUsCollection = db.collection('contact');

const ApiService = {
  postApi: async (formData) => {
    // try {
    //   const response = await axios.post(`${API_URL}/contact`, formData);
    //   return response.data;
    // } catch (error) {
    //   throw error;
    // }

    try {
      // Assuming 'contact' is the name of your Firestore collection
      const docRef = await contactUsCollection.add(formData);
      console.log('Document written with ID: ', docRef.id);
      return docRef.id; // Return the ID of the created document if needed
    } catch (error) {
      throw error;
    }
  },
};

export default ApiService;