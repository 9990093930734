import React, { useRef } from "react";
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faSquareFacebook,
  faSquareTwitter,
  faSquareInstagram,
  faSquareYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import Logo from "../../assets/images/LAHLogo.png";
import { Link } from "react-router-dom";
import "./NavBar.css";


const facebookPageLink = "https://www.facebook.com/your-facebook-page";

const courses = [
  {
    name: "CSharp",
    description: "",
    to: "/courses/csharp",
    icon: ChartPieIcon,
  },
  {
    name: "Java",
    description: "",
    to: "/courses/java",
    icon: CursorArrowRaysIcon,
  },
];

const tutorials = [
  {
    name: "CSharp",
    description: "",
    to: "/tutorials/csharp",
    icon: ChartPieIcon,
  },
  {
    name: "Java",
    description: "",
    to: "/tutorials/java",
    icon: CursorArrowRaysIcon,
  },
];
const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
];


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [activeTab, setActiveTab] = useState('home');

const handleTabClick = (tab) => {
  setActiveTab(tab);
};


  return (
    <div className="bg-white shadow-md fixed  w-screen z-10 top-navigation">
      <nav className="mx-auto max-w-full px-6 flex items-center justify-between py-4 " aria-label="Global">
        <div className="flex-initial flex">
          <a href="#" className="-m-1.5 p-1.5" title="LearnAtHouse">
            <span className="sr-only">LearnAtHouse</span>
            <img className="h-5 w-auto lg:h-8" src={Logo} alt="" />
          </a>
        </div>
      {/* On Mobile click to open menu  */}
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden justify-center flex-1 lg:flex lg:gap-x-8 nav-ml">
          <Link to="/" className={`text-base font-semibold leading-6 text-dark-brown nav-desk ${activeTab === 'home' ? 'is-active' : ''}`}
                  onClick={() => handleTabClick('home')} >
            Home
          </Link>
          <Link to="/aboutus" className={`text-base font-semibold leading-6 text-dark-brown nav-desk ${activeTab === 'about' ? 'is-active' : ''}`}
                    onClick={() => handleTabClick('about')}>
            About Us
          </Link>
          <Link to="/services" className={`text-base font-semibold leading-6 text-dark-brown nav-desk ${activeTab === 'service' ? 'is-active' : ''}`}
                    onClick={() => handleTabClick('service')}>
            Our Services
          </Link>
          <Popover className="relative">
            <Popover.Button className={`flex gap-x-1 text-base font-semibold leading-6 text-dark-brown nav-desk ${activeTab === 'course' ? 'is-active' : ''}`}
                    onClick={() => handleTabClick('course')}>
              Courses
              <ChevronDownIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {courses.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-base leading-6 hover:bg-gray-50"
                    >
                      {/* <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                          aria-hidden="true"
                        />
                      </div> */}
                      <div className="flex-auto">
                        <Link
                          to={item.to}
                          className="block font-semibold text-dark-brown"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>

              {/* <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {tutorials.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-base leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <Link to={item.to} className="block font-semibold text-dark-brown">
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                  {callsToAction.map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="flex items-center justify-center gap-x-2.5 p-3 text-base font-semibold leading-6 text-dark-brown hover:text-blue"
                    >
                      <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                      {item.name}
                    </Link>
                  ))}
                </div> 
              </Popover.Panel> */}
            </Transition>
          </Popover>

          <Link to="/contact" className={`text-base font-semibold leading-6 text-dark-brown nav-desk ${activeTab === 'contact' ? 'is-active' : ''}`}
                    onClick={() => handleTabClick('contact')}>
            Contact Us
          </Link>
        </Popover.Group>
              
      </nav>

      {/* Mobile Code Device */}
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-5 w-auto lg:h-8" src={Logo} alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Link
                  to="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-dark-brown hover:text-blue"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Home
                </Link>
                <Link
                  to="/aboutus"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-dark-brown hover:text-blue"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  About Us
                </Link>
                <Link
                  to="/services"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-dark-brown hover:text-blue"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Our Services
                </Link>

                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-dark-brown hover:text-blue">
                        Courses
                        <ChevronDownIcon
                          className={classNames(
                            open ? "rotate-180" : "",
                            "h-5 w-5 flex-none"
                          )}
                          aria-hidden="true"
                          onClick={() => setMobileMenuOpen(false)}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...courses].map((item) => (
                          <Link
                            key={item.name}
                            as="a"
                            to={item.to}
                            className="block rounded-lg py-2 pl-6 pr-3 text-base font-semibold leading-7 text-dark-brown hover:text-blue"
                          >
                            {item.name}
                          </Link>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Link
                  to="/contact"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:text-blue"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Contact Us
                </Link>
              </div>
            
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </div>
  );
}
