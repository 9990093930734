import React from "react";
import "./MeetYourMentors.css";

import DotNetTrainer from "../../assets/images/dotnettrainer.png";

import JavaTrainer from "../../assets/images/javatrainer.png";
import UiTrainer from "../../assets/images/uitrainer.png";
import MentorSocialConnect from "../../utility/MentorSocialConnect";

function MeetYourMentors() {
  return (
    <div className="bg-container bg-blue" id="mentors">
      <div className="mx-auto w-10/12 lg:py-[60px] py-8">
        <div className="mentors-container text-white">
          <div className="mentor-sub-section text-center lg:w-3/5 flex flex-wrap justify-center mx-auto pb-4">
            <h2 className="mentor-heading text-2xl lg:text-3xl font-bold tracking-tight uppercase">
              Meet your mentors
            </h2>
            {/* <p className="mt-6 text-lg leading-8">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet, quas exercitationem vel cumque unde sapiente placeat accusantium!
          </p> */}
          </div>
          <div className="mentor-section grid grid-col-1 lg:grid-cols-3 grid-flow-row-dense gap-4 mt-4 md:mt-6">
            {/* Mentor 1 */}
            <div className="mentor-info  flex flex-wrap justify-center hover:bg-violet-600">
              <div className="mentor-styling">
                <img
                  className="mentor-image"
                  src={DotNetTrainer}
                  width="150px"
                  height="150px"
                  alt="Online Dot Net Trainer"
                />
                <h2 className="font-bold  ">Sudheshwer Rai</h2>
                <h4 className="mb-4 text-gray-100 text-xs ">
                  Online Dot Net Trainer
                </h4>
              </div>
              <p className="mentor-experience">
                A Senior .NET Full Stack Engineer with 8+ years of experience is
                an experienced professional who specializes in developing
                software solutions using Microsoft's .NET.
              </p>
              {/* <MoreInfoButton></MoreInfoButton> */}
              <MentorSocialConnect
                facebookUrl="https://www.facebook.com/onlinedotnettrainer"
                instagramUrl="https://www.instagram.com/onlinedotnettrainer"
                twitterUrl="https://twitter.com/sudheshwer_rai"
                linkedinUrl="https://www.linkedin.com/company/online-dot-net-trainer"
              />
            </div>
            {/* Mentor 2 */}
            <div className="mentor-info flex flex-wrap justify-center hover:bg-violet-600">
              <div className="mentor-styling">
                <img
                  className="mentor-image"
                  src={JavaTrainer}
                  width="150px"
                  height="150px"
                  alt="Online Java Trainer"
                />
                <h2 className="font-bold ">Afroj Alam</h2>
                <h4 className="mb-4 text-gray-100 text-xs mb-4">
                  Online Java Trainer
                </h4>
              </div>
              <p className="mentor-experience">
                A technical architect in Java with over 10 years of experience
                in the IT industry. Who has strong ability to showcase
                proficiency in Java. His extensive knowledge extends to robust
                database management. Beyond his technical expertise.
              </p>
              {/* <MoreInfoButton></MoreInfoButton> */}
              <MentorSocialConnect
                facebookUrl="https://www.facebook.com/afroj.alam.14"
                instagramUrl="https://www.instagram.com/afoj786"
                twitterUrl="#"
                linkedinUrl="https://www.linkedin.com/in/afroj-alam-in/"
              />
            </div>
            {/* Mentor 3 */}
            <div className="mentor-info flex flex-wrap justify-center hover:bg-violet-600">
              <div className="mentor-styling">
                <img
                  className="mentor-image"
                  src={UiTrainer}
                  width="150px"
                  height="150px"
                  alt="Online Java Trainer"
                />
                <h2 className="font-bold">Mohd Imran</h2>
                <h4 className="mb-4 text-gray-100 text-xs  mb-4 ">
                  Online UI/UX Trainer
                </h4>
              </div>
              <p className="mentor-experience">
                A Senior UI/UX developer with 10+ years experience, i will
                contribute to the growth and development of aspiring designers,
                helping them acquire the skills needed for a successful career
                in UI/UX design.
              </p>
              {/* <MoreInfoButton></MoreInfoButton> */}
              <MentorSocialConnect
                facebookUrl="#"
                instagramUrl="#"
                twitterUrl="#"
                linkedinUrl="https://www.linkedin.com/in/mohammad-imran-61815a65/"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetYourMentors;
