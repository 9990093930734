import React from "react";
import { Link } from "react-router-dom";

import "./courses.css";

import { ReactComponent as Icon } from "../../assets/images/icons/icon-design.svg";
import { ReactComponent as Icon2 } from "../../assets/images/icons/icon-development.svg";
import { ReactComponent as Icon3 } from "../../assets/images/icons/icon-helpdesk.svg";
import { ReactComponent as Icon4 } from "../../assets/images/icons/icon-training.svg";

import forntendImg from "../../assets/images/courses/csharp.jpg";
import forntendImg02 from "../../assets/images/courses/java.jpg";
import forntendImg03 from "../../assets/images/courses/react.jpg";
import forntendImg04 from "../../assets/images/courses/mongo.jpg";

import bgImg from "../../assets/images/bg-02.jpg";

function Courses() {
  return (
    <>
      <div className="bg-container bg-cream">
        <div className="mx-auto w-10/12 lg:py-[25px] py-8 grid grid-cols-1 grid-flow-row-dense gap-4 ">
          <div className="">
            <div className="course-content" id="course">
              <h2 className="mx-auto text-center text-brown  font-bold leading-7 font-bold tracking-tight uppercase text-2xl lg:text-3xl">
                Our Courses
              </h2>
              <div className=" grid grid-col-1  grid-flow-row-dense gap-4 mt-4 md:grid-cols-2 xl:grid-cols-4 md:mt-10 ">
                <div class="course-card hover:scale-105 ease-in duration-300 hover:shadow-md">
                  <div class="course-img">
                    <img
                      src={forntendImg}
                      alt=""
                      className="object-cover object-center w-full rounded-lg"
                    />
                  </div>
                  <div class="course-content px-4 py-3 pb-6">
                    <h3 class="title font-medium mt-3 mb-4 text-lg ">
                      <Link to="/courses/csharp">CSharp with SQL server</Link>
                    </h3>
                    <div className="content-dis mt-6 text-sm text-gray-700">
                      {/* <b className="text-base text-black">Skills you'll gain:</b> */}
                      <p>
                        Empower your programming journey with our comprehensive
                        C# with SQL Server courses. Dive into the world of C#
                        programming language and SQL Server database management,
                        honing your skills to build robust and efficient
                        applications
                      </p>
                    </div>
                    <div class="course-duration text-sm text-gray-600 pt-6">
                      <p>Duration · 1 Month</p>
                    </div>
                    <div className="course-more">
                      <Link
                        to="/courses/csharp"
                        class="inline-block mt-2 text-blue"
                      >
                        <button
                          class="flex items-center gap-2   text-base font-medium align-middle transition-all  select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none "
                          type="button"
                        >
                          Learn More
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            ></path>
                          </svg>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="course-card hover:scale-105 ease-in duration-300 hover:shadow-md">
                  <div class="course-img">
                    <img
                      src={forntendImg02}
                      alt=""
                      className="object-cover object-center w-full rounded-lg"
                    />
                  </div>
                  <div class="course-content px-4 py-3 pb-6">
                    <h3 class="title font-medium mt-3 mb-4 text-lg ">
                      <Link to="/courses/java">Java, Spring with Mysql</Link>
                    </h3>
                    <div className="content-dis mt-6 text-sm text-gray-700">
                      {/* <b className="text-base text-black">Skills you'll gain:</b> */}
                      <p>
                        Elevate your programming prowess with our Java and
                        Spring with MySQL courses. Immerse yourself in the world
                        of Java programming, master the versatility of the
                        Spring framework, and delve into efficient MySQL
                        database management.{" "}
                      </p>
                    </div>
                    <div class="course-duration text-sm text-gray-600 pt-6">
                      <p>Duration · Course · 1 - 3 Months</p>
                    </div>
                    <div className="course-more">
                      <Link
                        to="/courses/java"
                        class="inline-block mt-2 text-blue"
                      >
                        <button
                          class="flex items-center gap-2   text-base font-medium align-middle transition-all  select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none "
                          type="button"
                        >
                          Learn More
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            ></path>
                          </svg>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div class="course-card hover:scale-105 ease-in duration-300 hover:shadow-md">
                  <div class="course-img">
                    <img
                      src={forntendImg03}
                      alt=""
                      className="object-cover object-center w-full rounded-lg"
                    />
                  </div>
                  <div class="course-content px-4 py-3 pb-6">
                    <h3 class="title font-medium mt-3 mb-4 text-lg ">
                      <a href="#">UI/UX Design and ReactJS</a>
                    </h3>
                    <div className="content-dis mt-6 text-sm text-gray-700">
                      {/* <b className="text-base text-black">Skills you'll gain:</b> */}
                      <p>
                        Shape the future of digital experiences with our UI/UX
                        Design and ReactJS courses. Dive into the art of
                        creating visually stunning and user-centric designs,
                        mastering the principles of user interface and
                        experience.
                      </p>
                    </div>
                    <div class="course-duration text-sm text-gray-600 pt-6">
                      <p>Duration · Course · 1 Month</p>
                    </div>
                    <div className="course-more">
                      <a href="#" class="inline-block mt-2 text-blue">
                        <button
                          class="flex items-center gap-2   text-base font-medium align-middle transition-all  select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none "
                          type="button"
                        >
                          Learn More
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            ></path>
                          </svg>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="course-card hover:scale-105 ease-in duration-300 hover:shadow-md">
                  <div class="course-img">
                    <img
                      src={forntendImg04}
                      alt=""
                      className="object-cover object-center w-full rounded-lg"
                    />
                  </div>
                  <div class="course-content px-4 py-3 pb-6">
                    <h3 class="title font-medium mt-3 mb-4 text-lg ">
                      <a href="#">SQL, MySQL and MongoDB</a>
                    </h3>
                    <div className="content-dis mt-6 text-sm text-gray-700">
                      {/* <b className="text-base text-black">Skills you'll gain:</b> */}
                      <p>
                        Unlock the realm of database management with our
                        comprehensive courses covering SQL, MySQL, and MongoDB.
                        Dive into the intricacies of relational database
                        management, explore the flexibility of NoSQL solutions,
                        and immerse yourself in the dynamic world of MongoDB.
                      </p>
                    </div>
                    <div class="course-duration text-sm text-gray-600 pt-6">
                      <p>Duration · Course · 1 Month</p>
                    </div>
                    <div className="course-more">
                      <a href="#" class="inline-block mt-2 text-blue">
                        <button
                          class="flex items-center gap-2   text-base font-medium align-middle transition-all  select-none disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none "
                          type="button"
                        >
                          Learn More
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="currentColor"
                            class="w-4 h-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                            ></path>
                          </svg>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Courses;
