import React, { useState, useEffect } from "react";
import "./HeroSection.css";

import CSharpTraining from "../../assets/images/CSharpTraining.jpeg";
import JavaTraining from "../../assets/images/javatraining.jpeg";
import ASPMobile from "../../assets/images/hero-m-01.jpg";
import JavaMobile from "../../assets/images/hero-m-02.jpg";


function HeroSection() {
  const [slideIndex, setSlideIndex] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      plusSlides(1);
    }, 4000); // Change slide every 4 seconds (adjust as needed)

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [slideIndex]);

  function plusSlides(n) {
    setSlideIndex((prevIndex) => {
      let newIndex = prevIndex + n;

      if (newIndex > 2) {
        newIndex = 1; // Wrap around to the first slide
      } else if (newIndex < 1) {
        newIndex = 2; // Wrap around to the last slide
      }

      return newIndex;
    });
  }

  function currentSlide(n) {
    setSlideIndex(n);
  }

  function showSlides(n) {
    let i;
    let slides = document.getElementsByClassName("mySlides");
    if (n > slides.length) {
      currentSlide(1);
    }
    if (n < 1) {
      currentSlide(slides.length);
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slides[slideIndex - 1].style.display = "block";
  }

  return (
    <div className="bg-container bg-black-800 ">
      <div className="hero-section mx-auto w-10/12 hero-slider" id="herosection">
        <div className="slideshow-container">
          {Array.from({ length: 2 }).map((_, index) => (
            <div key={index} className={`mySlides fade ${slideIndex === index + 1 ? "block" : "hidden"}`}>
              {/* Add your image components here */}
              {index + 1 === 1 && (
                <>
                  <img src={CSharpTraining} alt="CSharp Training" style={{ width: "100%" }} className="hidden sm:block" />
                  <img src={ASPMobile} alt="CSharp Training" style={{ width: "100%" }} className="block sm:hidden" />
                </>
              )}
              {index + 1 === 2 && (
                <>
                  <img
                    src={JavaTraining}
                    alt="Java Web Api Project based training"
                    style={{ width: "100%" }}
                    className="hidden sm:block"
                  />
                  <img src={JavaMobile} alt="Java with Web Api Project based training" style={{ width: "100%" }} className="block sm:hidden" />
                </>
              )}
              {/* Add more slides as needed */}
            </div>
          ))}
          {/* Next and previous buttons */}
          <a className="prev text-lg p-2 sm:text-sm sm:p-3" onClick={() => plusSlides(-1)}>
            &#10094;
          </a>
          <a className="next text-lg p-2 text-sm p-1 sm:text-sm sm:p-3" onClick={() => plusSlides(1)}>
            &#10095;
          </a>
        </div>
      </div>
    
    </div>
  );
}


export default HeroSection;