import React from "react";

export default function CsharpTutorials() {
  return (
    <>
      <div className="section">
        <h2>Welcome To CSharp Tutorials</h2>
      </div>
    </>
  );
}
