import React from "react";
import { useNavigate } from 'react-router-dom';

import "./MoreInfoButton.css";
import { Button } from "@material-tailwind/react";

function MoreInfoButton({ redirectUrl }) {
  const navigate = useNavigate();

  const handleMoreInfoClick = () => {
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      // Handle the case when redirectUrl is not provided
    }
  };

  return (
    <>
      <div>
        <Button  onClick={handleMoreInfoClick} className="mt-6 bg-material-primary h-3r" >
          More Info...</Button>
      </div>
    </>
  );
}

export default MoreInfoButton;
