import React from "react";

export default function JavaTutorials() {
  return (
    <>
      <div className="section">
        <h2>Welcome To Java Tutorials</h2>
      </div>
    </>
  );
}
