import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./modules/landingpage/LandingPage";
import JavaPage from "./modules/tutorials/JavaPage";
import CsharpPage from "./modules/tutorials/CsharpPage";
import CsharpCourse from "./modules/courses/CsharpCourse";
import JavaCourse from "./modules/courses/JavaCourse";

import PrivacyPolicyPage from "./modules/landingpage/PrivacyPolicyPage";
import TermServicePage from "./modules/landingpage/TermServicePage";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/aboutus" Component={Home} />
          <Route path="/services" Component={Home} />
          <Route path="/tutorials/java" Component={JavaPage} />
          <Route path="/tutorials/csharp" Component={CsharpPage} />
          <Route path="/courses/java" Component={JavaCourse} />
          <Route path="/courses/csharp" Component={CsharpCourse} />
          <Route path="/contact" Component={Home} />
          <Route path="/privacy-policy" Component={PrivacyPolicyPage} />
          <Route path="/term-of-service" Component={TermServicePage} />
          <Route path="/mentors" Component={Home} />
        </Routes>
      </BrowserRouter>
      {/* WhatsApp icon */}
      <a
        href="https://wa.me/919873476934"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: "fixed",
          bottom: "180px",
          right: "20px",
          background: "#25d366",
          color: "#fff",
          borderRadius: "50%",
          padding: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "24px",
          cursor: "pointer",
        }}
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>

      <a
        href="https://wa.me/918860858918"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          position: "fixed",
          bottom: "120px",
          right: "20px",
          background: "#1864ab",
          color: "#fff",
          borderRadius: "50%",
          padding: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          textDecoration: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "24px",
          cursor: "pointer",
        }}
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </>
  );
}
