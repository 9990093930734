import React from "react";
import TopFooter from "../components/common/TopFooter";
import Footer from "../components/common/Footer";

export default function FullFooter() {
  return (
    <>
      <TopFooter></TopFooter>
      <Footer></Footer>
    </>
  );
}
