import React, { useState, useEffect } from "react";

import "./scrollToTop.css";
const ScrollToTop = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);
  return (
    <a href="#" className={scroll ? "back-to-top active" : "back-to-top"}>
      <i className="bi bi-arrow-up-short"></i>
    </a>
  );
};

export default ScrollToTop;
