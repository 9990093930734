import React, { useState } from "react";
import "./ContactUs.css";
import { Button } from "@material-tailwind/react";

import ApiService from "../../services/ApiService";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
    timestamp: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      timestamp: new Date().toISOString(),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsSubmitting(true); // Disable the button on form submission
      const response = await ApiService.postApi(formData);

      console.log("Form submitted successfully:", response);
      // Clear the form fields after successful submission
      setFormData({
        fullName: "",
        phone: "",
        email: "",
        subject: "",
        message: "",
        timestamp: "",
      });
    } catch (error) {
      console.error("Error submitting the form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-container bg-blue" id="contact">
      <div className=" mx-auto w-10/12 lg:py-[60px] py-8">
        <div className="contactus-section ">
          <h2 className="mx-auto text-center text-white  font-bold  font-bold tracking-tight uppercase text-2xl lg:text-3xl">
            Contact Us
          </h2>
          <form onSubmit={handleSubmit}>
            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4">
              <div class="sm:col-span-2">
                <label
                  for="first-name"
                  class="block font-medium leading-6 text-white"
                >
                  Full Name
                </label>
                <div class="mt-2">
                  <input
                    type="text"
                    name="fullName"
                    id="first-name"
                    autocomplete="given-name"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    required
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="last-name"
                  class="block font-medium leading-6 text-white"
                >
                  Phone No
                </label>
                <div class="mt-2">
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                    autocomplete="family-name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="email"
                  class="block font-medium leading-6 text-white"
                >
                  Email address
                </label>
                <div class="mt-2">
                  <input
                    id="email"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    autocomplete="email"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div class="sm:col-span-2">
                <label
                  for="country"
                  class="block font-medium leading-6 text-white"
                >
                  Course
                </label>
                <div class="mt-2">
                  <select
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                    autocomplete="country-name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  >
                    <option value="">Select Course</option>
                    <option value="DotNet">Dot Net</option>
                    <option value="Java">Java</option>
                    {/* <option value="Angular">Angular</option> */}
                  </select>
                </div>
              </div>
              <div class="sm:col-span-4">
                <label
                  for="region"
                  class="block font-medium leading-6 text-white"
                >
                  Message
                </label>
                <div class="mt-2">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                    rows={5}
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="mt-6 flex items-center justify-center gap-x-6">
              <Button
                type="submit"
                className="bg-material-blue h-3r text-brown ripple={true}"
                disabled={isSubmitting}
              >
                {" "}
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
              <Button
                type="button"
                className="bg-material-blue h-3r text-brown ripple={true}"
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
