import React from "react";
import { useNavigate } from 'react-router-dom';
import "./MentorSocialConnect.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSquareFacebook,
  faSquareTwitter,
  faSquareInstagram,
  faSquareYoutube,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

function MentorSocialConnect({ facebookUrl, instagramUrl, twitterUrl, linkedinUrl }) {
  return (
    <>
      <div className="social-connect-mentor">
        <ul className="social-network-mentor">
          <li>
            <a
              className="custom-icon-social-bottom-mentor"
              href={facebookUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faSquareFacebook} />
            </a>
          </li>
          <li>
            <a
              className="custom-icon-social-bottom-mentor"
              href={instagramUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faSquareInstagram} />
            </a>
          </li>
          <li>
            {" "}
            <a
              className="custom-icon-social-bottom-mentor"
              href={twitterUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faSquareTwitter} />
            </a>
          </li>
          <li>
            {" "}
            <a
              className="custom-icon-social-bottom-mentor"
              href={linkedinUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

export default MentorSocialConnect;
