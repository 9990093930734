import React from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

import HeroSection from "../../components/landingpage/HeroSection";
import Services from "../../components/landingpage/services";
import AboutUs from "../../components/landingpage/AboutUs";
import Courses from "../../components/landingpage/courses";


import MeetYourMentors from "../../components/landingpage/MeetYourMentors";
import LetUsTalk from "../../components/common/LetUsTalk";
import FullHeader from "../../utility/FullHeader";
import FullFooter from "../../utility/FullFooter";
import ScrollTop from "../../components/common/ScrollToTop";
import FAQ from "../../components/landingpage/FAQ";
import Testimonial from "../../components/common/Testimonial";
import ContactUs from "../../components/landingpage/ContactUs";
import SubscribeToNewsletter from "../../components/common/SubscribeToNewsletter";

function LandingPage() {
  const location = useLocation();

  useEffect(() => {
    const elementId = location.pathname.substring(1); // Remove the leading '/' from the URL
    scrollToElement(elementId);
  }, [location]);

  const scrollToElement = (elementId) => {
    const element =
      elementId == ""
        ? document.getElementById("herosection")
        : document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };
  return (
    <>
      <header>
        <FullHeader></FullHeader>
      </header>
      <main>
        <HeroSection></HeroSection>
        <Courses></Courses>
        <Services></Services>
        <AboutUs></AboutUs>
        <MeetYourMentors></MeetYourMentors>
        <FAQ></FAQ>
        <Testimonial></Testimonial>
        <LetUsTalk></LetUsTalk>
        <ContactUs></ContactUs>
        <SubscribeToNewsletter></SubscribeToNewsletter>
      </main>
      <footer>
        <FullFooter></FullFooter>
      </footer>
      <ScrollTop></ScrollTop>
    </>
  );
}

export default LandingPage;
