import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./courses.css";

import {
  faShareNodes,
  faUsersLine,
  faClock,
  faCirclePlay,
  faCircleCheck,
  faStar,
  faUser,
  faComment,
  faStarHalfStroke,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";

import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

export default function CsharpCourse() {
  const [open, setOpen] = React.useState(1);
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  const [activeTab, setActiveTab] = useState("tab-1");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const location = useLocation();

  useEffect(() => {
    const elementId = location.pathname.substring(1); // Remove the leading '/' from the URL
    scrollToElement(elementId);
  }, [location]);

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <div className="bg-container bg-light-2" id="courses/csharp">
        <div className=" content-container mx-auto max-w-7xl px-4 xl:px-0">
          <div className="courses-header-section">
            <div className="course-heading">
              <h1 className="font-bold leading-7 text-2xl lg:text-3xl tracking-tight ">
                The Complete Csharp Course
              </h1>
            </div>
            <div className="courses-aside mt-6 flex text-sm">
              <div className="flex-1">
                <div className="course-tag">
                  <span>Keywords: </span>
                  <a href="" className="tracking-tight text-gray-950">
                    C# Basic to Advance Training
                  </a>
                  ,
                  <a href="" className="tracking-tight text-gray-950 ml-1">
                    Best Online Dot Net Training
                  </a>
                  ,
                  <a href="" className="tracking-tight text-gray-950 ml-1">
                    C# beginner to advanced training
                  </a>
                  ,
                  <a href="" className="tracking-tight text-gray-950 ml-1">
                    Learn C# online
                  </a>
                  ,
                  <a href="" className="tracking-tight text-gray-950 ml-1">
                    C# programming tutorials
                  </a>
                  ,
                  <a href="" className="tracking-tight text-gray-950 ml-1">
                    Best C# online training
                  </a>
                </div>
              </div>

              <div class="flex-1 justify-items-end">
                <div class="flex justify-end">
                  <a href="#" class="text-slate-600 hover:text-blue-800">
                    <FontAwesomeIcon icon={faShareNodes} /> Share
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-container">
        <div className=" mx-auto max-w-7xl pt-10 pb-10 px-4 xl:px-0">
          <section class="layout-pt-md layout-pb-md">
            <div class="page-nav-menu -line">
              <div class="flex gap-6">
                <div>
                  <a
                    href="#overview"
                    className={`pb-4 page-nav-menu__link ${
                      activeTab === "tab-1" ? "is-active" : ""
                    }`}
                    onClick={() => handleTabClick("tab-1")}
                  >
                    Who should join this course?
                  </a>
                </div>

                <div>
                  <a
                    href="#course-content"
                    className={`pb-4 page-nav-menu__link ${
                      activeTab === "about" ? "is-active" : ""
                    }`}
                    onClick={() => handleTabClick("about")}
                  >
                    Course Content
                  </a>
                </div>
              </div>
            </div>
            {/* Overview section */}
            <div id="overview" class="pt-6 lg:pt-10 to-over">
              <h2 class=" leading-7 text-lg lg:text-xl tracking-tight mb-6 font-medium">
                Who should join this course?
              </h2>
              <div class="show-more  mt-30 js-show-more is-active ">
                <div class="show">
                  <ul className="ul-list y-gap-15 pt-30 list-disc">
                    <li>Even if you don't have programing backgroud</li>
                    <li>If you want to learn C# from scratch.</li>
                    <li>If you want to become a dot net developer</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Course section */}
            <div id="course-content" class="pt-10 lg:pt-20">
              <h2 class="leading-7 text-lg lg:text-2xl tracking-tight mb-6 font-medium">
                Course Content
              </h2>
              <div class="flex justify-between items-center mt-30">
                <div class="">30 Days • 163 Lectures</div>
                <a href="#" class="underline text-blue">
                  Expand All Sections
                </a>
              </div>
              <div class="pt-4 lg:pt-10">
                <strong>Important Note:</strong> The duration may be extended
                based on the complexity of the topic, so there is no need to
                worry about the number of days.
              </div>
              <div class="mt-6">
                <Accordion
                  className="accordion accordion-container"
                  open={open === 1}
                  icon={<Icon id={1} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(1)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 1 - An Introduction to the Basics of C# Programming
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      14 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>
                        Exploring C# Programming: A Comprehensive Guide to C#
                        Language Basics
                      </li>
                      <li>
                        Step-by-Step Guide: Converting C# Source Code to Machine
                        Code Made Easy
                      </li>
                      <li>
                        A Comprehensive Introduction to the .NET Framework and
                        .NET Core
                      </li>
                      <li>
                        Demystifying the Common Language Runtime (CLR): Key
                        Insights and Functions
                      </li>
                      <li>.DLL vs .EXE</li>
                      <li>
                        Understanding the Role of Assembly Manifest, Metadata,
                        and MSIL
                      </li>
                      <li>
                        Understanding CTS, CLS, and JIT in .NET: A Comprehensive
                        Guide
                      </li>
                      <li>
                        Exploring the Versatility of C#: Applications You Can
                        Develop with C#
                      </li>
                      <li>
                        The Evolution of C#: A Journey Through C# History and
                        Milestones
                      </li>
                      <li>
                        Demystifying the Structure of C# Programs: In-Depth
                        Exploration of Namespace, Class, Method, and Object
                      </li>
                      <li>
                        Unlocking the Power of the 'return' Keyword in C#: A
                        Comprehensive Guide
                      </li>
                      <li>
                        Mastering Input and Output Operations in C#: A
                        Comprehensive Guide
                      </li>
                      <li>Exploring the Main Method in C#</li>
                      <li>
                        Step-by-Step Guide: Compiling, Running, and Debugging
                        Code in C#
                      </li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 2}
                  icon={<Icon id={2} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(2)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 2 - Understanding Data Types and Variables in C#: A
                      Comprehensive Guide
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      11 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>
                        Exploring Local, Field, and Parameterized Variables in
                        C#: A Detailed Overview
                      </li>
                      <li>
                        Built-In Data Types in C#: A Guide to Predefined Data
                        Types
                      </li>
                      <li>
                        Creating User-Defined Data Types in C#: A Comprehensive
                        Guide
                      </li>
                      <li>
                        Distinguishing Value Types and Reference Types in C#: A
                        Detailed Overview
                      </li>
                      <li>Understanding the Garbage Collector in C#</li>
                      <li>Unlocking the Power of the 'var' Keyword in C#</li>
                      <li>Mastering Implicit and Explicit Conversions in C#</li>
                      <li>
                        Demystifying the Object Hierarchy in C#: A Comprehensive
                        Exploration
                      </li>
                      <li>
                        Understanding Boxing and Unboxing in C#: An In-Depth
                        Guide
                      </li>
                      <li>
                        Exploring Const and Readonly in C#: A Comprehensive
                        Guide
                      </li>
                      <li>Exploring String and StringBuilder in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 3}
                  icon={<Icon id={3} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(3)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 3 - Understanding Operator Types in C#: A
                      Comprehensive Guide
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      8 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What Are Operators in C#?</li>
                      <li>Exploring Operator Types in C#</li>
                      <li>Mastering Arithmetic Operators in C#</li>
                      <li>Unlocking the Assignment Operator in C#</li>
                      <li>Understanding Unary Operators in C#</li>
                      <li>Mastering Comparison Operators in C#</li>
                      <li>
                        'Logical Opeartor in C#' make this tile seo friendly and
                        grammer issue and also check copy right issue.
                      </li>
                      <li>Exploring the Ternary Operator in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 4}
                  icon={<Icon id={4} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(4)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 4 - Exploring Conditional Constructs in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      7 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>If-Else Condition in C# Explained</li>
                      <li>Nested If-Else Statements in C#</li>
                      <li>When and Why Should You Use If-Else Conditions? </li>
                      <li>Switch-Case Statements in C#</li>
                      <li>The 'Break' Keyword in C#</li>
                      <li>The 'Continue' Keyword in C#</li>
                      <li>The 'Goto' Keyword in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 5}
                  icon={<Icon id={5} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(5)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 5 - Loops in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      8 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is a Loop in C#?</li>
                      <li>Why Do We Use Loops in C#?</li>
                      <li>Types of Loops in C#</li>
                      <li>The While Loop in C#</li>
                      <li>The Do-While Loop in C#</li>
                      <li>The For Loop in C#</li>
                      <li>The Foreach Loop in C#</li>
                      <li>Understanding Infinite Loops in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 6}
                  icon={<Icon id={6} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(6)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 6 - Access Specifiers and Custom DLLs in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      9 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is an Access Specifier in C#?</li>
                      <li>Types of Access Specifiers in C#</li>
                      <li>Public Access Specifier in C#</li>
                      <li>Private Access Specifier in C#</li>
                      <li>Protected Access Specifier in C#</li>
                      <li>Internal Access Specifier in C#</li>
                      <li>Protected Internal Access Specifier in C#</li>
                      <li>How to Create a Custom DLL in C#?</li>
                      <li>Benefits of Using Custom DLLs in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 7}
                  icon={<Icon id={7} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(7)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 7 - Access Specifiers and Custom DLLs in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      9 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is an Access Specifier in C#?</li>
                      <li>Types of Access Specifiers in C#</li>
                      <li>Public Access Specifier in C#</li>
                      <li>Private Access Specifier in C#</li>
                      <li>Protected Access Specifier in C#</li>
                      <li>Internal Access Specifier in C#</li>
                      <li>Protected Internal Access Specifier in C#</li>
                      <li>How to Create a Custom DLL in C#?</li>
                      <li>Benefits of Using Custom DLLs in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 8}
                  icon={<Icon id={8} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(8)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 8 - Types of Functions or Methods in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      12 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is a Method in C#?</li>
                      <li>Declaring a Method in C#</li>
                      <li>Calling a Method in C#</li>
                      <li>Types of Methods in C#</li>
                      <li>Non-Static Method or Instance Method in C#</li>
                      <li>Static Method in C#</li>
                      <li>Virtual Method in C#</li>
                      <li>Abstract Method in C#</li>
                      <li>Partial Method in C#</li>
                      <li>Extension Method in C#</li>
                      <li>Class Object in C#</li>
                      <li>Static Variable in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 9}
                  icon={<Icon id={9} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(9)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 9 - Exploring Classes in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      5 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>Non-Static Class</li>
                      <li>Static Class</li>
                      <li>Partial Class</li>
                      <li>Abstract Class</li>
                      <li>Sealed Class</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 10}
                  icon={<Icon id={10} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(10)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 10 - Constructors and Destructors in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      5 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is a Constructor in C#?</li>
                      <li>Types of Constructors in C#</li>
                      <li>Default or Parameterless Constructor in C#</li>
                      <li>Parameterized Constructor in C#</li>
                      <li>Copy Constructor in C#</li>
                      <li>Static Constructor in C#</li>
                      <li>Private Constructor in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 11}
                  icon={<Icon id={11} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(11)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 11 - Working with Parameters in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      5 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>Understanding the Use of Parameters in C#</li>
                      <li>Value Type Parameters in C#</li>
                      <li>Reference Type Parameters in C#</li>
                      <li>Output Parameters in C#</li>
                      <li>Param Array Parameters in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 12}
                  icon={<Icon id={12} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(12)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 12 - Interfaces and Arrays in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      9 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is an Interface?</li>
                      <li>Why Do We Use Interfaces in C#?</li>
                      <li>Benefits of Interfaces in C#</li>
                      <li>What is an Array in C#?</li>
                      <li>Benefits of Arrays in C#</li>
                      <li>Types of Arrays in C#</li>
                      <li>Single-Dimensional Array in C#</li>
                      <li>Multi-Dimensional Array in C#</li>
                      <li>Jagged Array in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 13}
                  icon={<Icon id={13} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(13)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 13 - Structures and Enums in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      6 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is a Structure in C#?</li>
                      <li>Class vs Structure in C#</li>
                      <li>Benefits of Structures in C#</li>
                      <li>What is an Enum in C#?</li>
                      <li>Why Do We Use Enums in C#?</li>
                      <li>Benefits of Enums in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 14}
                  icon={<Icon id={14} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(14)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 14 - Exception Handling in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      5 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is an Exception in C#?</li>
                      <li>Try, Catch, Finally, Throw Keywords in C#</li>
                      <li>How to Handle Exceptions in C#?</li>
                      <li>Benefits of Exception Handling in C#</li>
                      <li>Standard Exception Classes</li>
                      <li>User-Defined Exceptions</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 15}
                  icon={<Icon id={15} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(15)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 15 - Properties and Indexers in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      4 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is a Property in C#?</li>
                      <li>Why Do We Use Properties in C#?</li>
                      <li>Benefits of Properties in C#</li>
                      <li>Indexer Property in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 16}
                  icon={<Icon id={16} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(16)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 16 - Collections in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      7 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is a Collection in C#?</li>
                      <li>Why Do We Use Collections in C#?</li>
                      <li>
                        Difference between Non-Generic and Generic Collections
                        in C#
                      </li>
                      <li>Non-Generic Collections in C#</li>
                      <li>ArrayList, SortedList, HashTable, Stack, Queue</li>
                      <li>Generic Collections in C#</li>
                      <li>
                        List, Dictionary, SortedList, HashSet, Stack, Queue
                      </li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 17}
                  icon={<Icon id={17} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(17)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 17 - Threading in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      4 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is Threading in C#?</li>
                      <li>Why Do We Use Threading in C#?</li>
                      <li>Benefits of Threading in C#</li>
                      <li>Types of Threading in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 18}
                  icon={<Icon id={18} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(18)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 18 - Delegates and Events in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      6 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is a Delegate in C#?</li>
                      <li>Creating and Using Delegates in C#</li>
                      <li>Why Do We Use Delegates in C#?</li>
                      <li>Benefits of Delegates in C#</li>
                      <li>Delegate Types in C#</li>
                      <li>Defining and Using Events in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 19}
                  icon={<Icon id={19} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(19)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 19 - Streams in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      3 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is the Use of Streams in C#?</li>
                      <li>File I/O and Streams in C#</li>
                      <li>Stream Readers and Writers Classes in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 20}
                  icon={<Icon id={20} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(20)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 20 - OOPS in C# - Inheritance
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      3 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is Inheritance in C#?</li>
                      <li>Inheritance Types in C#</li>
                      <li>Benefits of Inheritance in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 21}
                  icon={<Icon id={21} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(21)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 21 - OOPS in C# - Abstraction & Encapsulation
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      3 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is Abstraction in C#?</li>
                      <li>What is Encapsulation in C#?</li>
                      <li>
                        Relationship Between Abstraction and Encapsulation
                      </li>

                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 22}
                  icon={<Icon id={22} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(22)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 22 - OOPS in C# - Polymorphism
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      3 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is Polymorphism in C#?</li>
                      <li>Types of Polymorphism in C#</li>
                      <li>Benefits of Polymorphism in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 23}
                  icon={<Icon id={23} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(23)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 23 - Attributes, Reflection & Anonymous Methods in C#
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      3 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>Attributes in C#</li>
                      <li>Reflection in C#</li>
                      <li>Anonymous Methods in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 24}
                  icon={<Icon id={24} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(24)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 24 - Asynchronous Programming
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      1 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>Asynchronous Programming in C#</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 25}
                  icon={<Icon id={25} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(25)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 25 - CRUD Operations with Collections
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      1 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>CRUD Operations with Collections</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 26}
                  icon={<Icon id={26} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(26)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 26 - SQL Server Part 1
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      4 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is SQL Server?</li>
                      <li>What are database objects?</li>
                      <li>How to create a database?</li>
                      <li>How to create a table?</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 27}
                  icon={<Icon id={27} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(27)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 27 - SQL Server Part 2
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      3 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>DML Operations</li>
                      <li>Understanding Drop and Truncate Commands</li>
                      <li>Essential SQL Keywords</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 28}
                  icon={<Icon id={28} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(28)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 28 - SQL Server Part 3
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      3 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>What is Stored Procedure?</li>
                      <li>How to Create Stored Procedure?</li>
                      <li>DML Operation with SP</li>
                      <li>Lab Session</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 29}
                  icon={<Icon id={29} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(29)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 29 - CRUD Operations With DB - Part 1
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      1 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>CRUD Operations With SQL Server</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
                <Accordion
                  className="accordion accordion-container"
                  open={open === 30}
                  icon={<Icon id={30} open={open} />}
                >
                  <AccordionHeader
                    className="accordion-header rounded-md bg-blue text-white hover:text-white font-['Proxima Nova']"
                    onClick={() => handleOpen(30)}
                  >
                    <div className="accordion-header-heading lg:flex-none flex-auto  text-base ">
                      Day 30 - CRUD Operations With DB - Part 2
                    </div>
                    <div className="course-duration flex-1 text-right text-base ">
                      1 Lectures
                    </div>
                  </AccordionHeader>
                  <AccordionBody className="accordion-content text-base font-normal text-gray">
                    <ul className="ul-list y-gap-15 pt-30 list-disc	">
                      <li>CRUD Operations With SQL Server</li>
                      <li>Home Work</li>
                    </ul>
                  </AccordionBody>
                </Accordion>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
