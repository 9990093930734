import React from "react";
import "../../components/common/Footer.css";

function Footer() {
  return (
    <p className="footer-section ">
      © Copyright 2024 LearnAtHouse. All Rights Reserved
    </p>
  );
}

export default Footer;
